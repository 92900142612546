.JoinOurFastGrowingNetworkBlock_MainContainer {
  position: relative;
  bottom: -100px;
}

#JoinOurFastGrowingNetworkBlock_h3 {
  position: relative;
  left: 400px;
  /* font-size: 200px; */
  font-family: Barlow, sans-serif;
  color: #040a17;
  font-size: 44px;
  line-height: 1.13em;
  font-weight: 700;
  margin: 25px 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  /* For making the rolling text on the far left */
  left: 15%;
  column-count: auto;
  word-wrap: break-word;
  width: 600px;
}

#JoinOurFastGrowingNetworkBlock_p {
  font-family: Barlow, sans-serif;
  margin: 35px 0 0;
  font-size: 21px;
  line-height: 1.43em;
  color: #040a17;
  column-count: auto;
  word-wrap: break-word;
  width: 600px;
  position: relative;
  left: 15%;
}

#JoinOurFastGrowingNetworkBlock_img {
  position: relative;
  top: -200px;
  right: -70%;
}

@media screen and (max-width: 600px) {
  .JoinOurFastGrowingNetworkBlock_MainContainer {
    position: relative;
    bottom: -50px;
  }

  #JoinOurFastGrowingNetworkBlock_h3 {
    position: relative;
    left: 15%;
    /* top: 100px; */
    column-count: auto;
    word-wrap: break-word;
    width: 300px;
  }

  #JoinOurFastGrowingNetworkBlock_p {
    font-family: Barlow, sans-serif;
    margin: 35px 0 0;
    font-size: 21px;
    line-height: 1.43em;
    color: #040a17;
    column-count: auto;
    word-wrap: break-word;
    width: 300px;

    /* For making the rolling text on the far left */

    left: 15%;
  }

  #JoinOurFastGrowingNetworkBlock_img {
    position: relative;
    left: 15%;
    top: 100px;
  }
}
