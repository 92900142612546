/* Import the Poppins font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;800&display=swap");

/* Style the body */
.my-body {
  display: flex;
  flex-flow: column;
  align-items: center;
  font-family: "Poppins", serif;
  background: rgb(238, 174, 202);
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
}

/* Style the h1 */
h1 {
  display: flex;
  font-weight: 800;
  margin: 1rem 0 0;
}

/* Style the ul */
ul {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  flex-wrap: wrap;
  list-style: none;

  /* Style the li */
  li {
    display: flex;
    width: 22rem;
    height: 25rem;
    margin: 0.25rem;
    flex-flow: column;
    border-radius: 0.2rem;
    padding: 1rem;
    font-weight: 300;
    font-size: 0.8rem;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-width: 1px !important;
    border-style: solid !important;

    /* Style the time */
    time {
      font-size: 2rem;
      margin: 0 0 1rem 0;
      font-weight: 500;
    }

    /* Style the .today class */
    .today {
      time {
        font-weight: 800;
      }
      background: #ffffff70;
    }
  }
}
