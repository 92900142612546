/* .home__bg {
  background-image: url(https://images.unsplash.com/photo-1529156069898-49953e39b3ac?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80);
  height: 92vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
} */

/* Importing Nunito font */

@import url("https://fonts.googleapis.com/css?family=Nunito");

/* * {
  font-family: Nunito, sans-serif;
} */

body {
  font-family: Nunito, sans-serif;
}

/*  */

/* .componentsInside_GlobPic {
  background-image: url(../assets/shape_6.png);
  image-rendering: url(../assets/map_line.pngS);
  height: 80vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-x: hidden;
} */

.image {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-message-icon {
  margin-left: 10px;
  display: inline-block;
}

/* .typeWriterDiv {
  font-size: 2rem;
  color: #ffffff;
  padding-right: 50px;
} */

/* .getStartedButton {
  position: absolute;
  bottom: 40px;
  font-size: 10px;
} */

@media screen and (max-width: 600px) {
  /* .typeWriterDiv {
    font-size: 1rem;
  } */

  .componentsInside_GlobPic {
    height: 60vh;
  }
}

/* WeMakeConnectingEasier_Block */

.WeMakeConnectingEasier_Block {
  position: relative;
}

.WeMakeConnectingEasier_Block h3#h3In_WeMackCEBlock {
  color: white;
  position: absolute;
  top: 150px;
  left: 100px;
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-weight: 400;
}

.WeMakeConnectingEasier_Block p#pIn_WeMackCEBlock {
  color: white;
  font-size: 1rem;
  position: absolute;
  top: 200px;
  font-family: Nunito, sans-serif;
  font-weight: 400;
}

.WeMakeConnectingEasier_Block img#imgIn_WeMackCEBlock {
  /* CSS properties here */
  /* height: 70vh; */
  /* position: absolute; */
  padding-bottom: 10px;
  /* object-fit: contain; */
  width: 50%;
  height: 70%;
}
.WeMakeConnectingEasier_Block img#pplPic_WeMackCEBlock {
  /* CSS properties here */
  /* height: 70vh; */
  /* position: absolute; */
  padding-bottom: 10px;
  /* object-fit: contain; */
  width: 49.5%;
  height: 49.5%;
}

@media screen and (max-width: 600px) {
  .WeMakeConnectingEasier_Block h3#h3In_WeMackCEBlock {
    font-size: 1rem;
    top: 50px;
    left: 30px;
  }

  .WeMakeConnectingEasier_Block p#pIn_WeMackCEBlock {
    top: 100px;
  }
  .WeMakeConnectingEasier_Block img#imgIn_WeMackCEBlock {
    width: 100%;
    padding-bottom: 0%;
  }

  .WeMakeConnectingEasier_Block img#pplPic_WeMackCEBlock {
    width: 100%;
  }
}

/* Rolling Div */

.main {
  /* background-color: #e63946; */
  margin: 0;
  padding: 0;
  font-family: "Arvo";
}

.main {
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.main h1 {
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  font-size: 2rem;
}

.roller {
  height: 4.125rem;
  line-height: 4rem;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #1d3557;
}

#spare-time {
  font-size: 1rem;
  font-style: italic;
  letter-spacing: 1rem;
  margin-top: 0;
  color: #a8dadc;
}

.roller #rolltext {
  position: absolute;
  top: 0;
  animation: slide 5s infinite;
}

@keyframes slide {
  0% {
    top: 0;
  }
  25% {
    top: -4rem;
  }
  50% {
    top: -8rem;
  }
  72.5% {
    top: -12.25rem;
  }
}

@media screen and (max-width: 600px) {
  .main h1 {
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    font-size: 2.125rem;
  }

  .roller {
    font-size: 1.1rem;
    height: 2.6rem;
    line-height: 2.125rem;
    position: relative;
    top: 200px;
    left: -140px;
  }

  #spare-time {
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }

  .roller #rolltext {
    animation: slide-mob 5s infinite;
  }

  @keyframes slide-mob {
    0% {
      top: 0;
    }
    25% {
      top: -2.125rem;
    }
    50% {
      top: -4.25rem;
    }
    72.5% {
      top: -6.375rem;
    }
  }
}
/* End Rolling Div  */
