@import url("https://fonts.googleapis.com/css?family=Nunito");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.whyJoinMunazem_Block {
  left: 100px;
}

.MainContainer {
  width: 100%;
  height: 100%;
  background: #fff;
  font-family: "Edu NSW ACT Foundation", cursive;
  font-family: Nunito, sans-serif;
}

body {
  font-size: 50px;
}

header,
footer {
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.3);
  height: 100vh;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer {
  margin-top: 50px;
  box-shadow: -3px -5px 5px rgba(0, 0, 0, 0.3);
}

main {
  /* box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.3), -3px -5px 5px rgba(0, 0, 0, 0.3);
  height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  gap: 50px;
  overflow: hidden;
}

main div {
  flex: 1;
  height: 80%;
  /* border: 2px solid #999; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: all ease-in 1s;
}

/* for adjusting the div top to buttom */

.child-two {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.child-two h1 {
  margin-bottom: 0;
}

.child-two p {
  margin-top: 0;
}

/*  */

main div:first-child {
  transform: translateX(-150%);
  opacity: 0;
}
main div:last-child {
  /* padding-bottom: 100px; */
  padding-left: 500px;
  transform: translateX(50%);
  opacity: 0;
  margin-top: -100px;
}

.slide-in {
  transform: translateX(0) !important;
  opacity: 1 !important;
}

@media screen and (max-width: 600px) {
  .MainContainer {
    display: flex;
    flex-direction: column;
  }

  .WhatWeDo_Block {
    position: relative;
    transform: translateX(-60px);
  }
  .child-one {
    position: relative;
    height: 20vh;
    width: 60vh;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .child-two {
    position: relative;

    top: 50px;
    padding-top: 80px;
    /* left: 0;
     top: 0;
    z-index: 2;
    padding-bottom: 0px;
    padding-left: 0px;   */
  }

  main div:last-child {
    /* padding-bottom: 100px; */
    padding-left: 0px;
    transform: translateX(50%);
    opacity: 0;
  }

  h1 {
    position: relative;
    left: 10px;
  }

  p {
    position: relative;
    left: 10px;
  }

  /* #makeWonderfulFriends {
    font-size: 10px;
  } */

  .slide-in {
    transform: translateX(0%) !important;
    opacity: 1 !important;
  }
}
