/* .login__bg {
    background-image: url(https://images.unsplash.com/photo-1577563908411-5077b6dc7624?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80);
    height: 92vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
} */

.Login_MainContainer {
  position: relative;
  bottom: -100px;
}

#LoginImage {
  position: relative;
  left: -150%;
  bottom: 450px;
}

@media screen and (max-width: 600px) {
  .Login_MainContainer {
    position: relative;
    bottom: -350px;
  }
  #LoginImage {
    position: relative;
    left: -15%;
    bottom: 654px;
    width: 350px;
  }
}
