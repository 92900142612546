@import url("https://fonts.googleapis.com/css?family=Nunito");

/* * {
  font-family: Nunito, sans-serif;
} */

body {
  font-family: Nunito, sans-serif;
}

.whoWeAre_Block {
  padding-left: 40px;
  /* padding-top: 500px; */
}

#WhoWeAre_BlueTitle {
  padding-bottom: 30px;
  color: #615dfa;
}

.whoWeAre_Block h3 {
  position: relative;
  left: 400px;
  /* font-size: 200px; */
  font-family: Barlow, sans-serif;
  color: #040a17;
  font-size: 44px;
  line-height: 1.13em;
  font-weight: 700;
  margin: 25px 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  /* For making the rolling text on the far left */
  left: 55%;
}

.WhoWeAre_P {
  font-family: Barlow, sans-serif;
  margin: 35px 0 0;
  font-size: 21px;
  line-height: 1.43em;
  color: #040a17;
  column-count: auto;
  word-wrap: break-word;
  width: 600px;

  /* For making the rolling text on the far left */
  position: relative;
  left: 55%;
}

#WeConnectPeopleImg {
  position: relative;
  top: -430px;
  /* bottom: 300px; */
}

#FindTheRightGroup_h3 {
  position: relative;
  top: 30px;
  left: 0%;
  top: -300px;
  column-count: auto;
  word-wrap: break-word;
  width: 600px;
}

#idFindTheRightGroup_p {
  font-family: Barlow, sans-serif;
  margin: 35px 0 0;
  font-size: 21px;
  line-height: 1.43em;
  color: #040a17;
  column-count: auto;
  word-wrap: break-word;
  width: 600px;
  position: relative;
  top: -200px;
}

#idFindTheRightGroup_img {
  position: relative;
  top: -450px;
  left: 75%;
}

@media screen and (max-width: 600px) {
  .whoWeAre_Block {
    padding-left: 50px;
    padding-top: 60px;
  }

  #WhoWeAre_BlueTitle {
    left: -30px;
  }

  .whoWeAre_Block h3 {
    left: 0px;
  }

  .WhoWeAre_P {
    font-family: Barlow, sans-serif;
    margin: 35px 0 0;
    font-size: 21px;
    line-height: 1.43em;
    color: #040a17;
    column-count: auto;
    word-wrap: break-word;
    width: 300px;

    /* For making the rolling text on the far left */

    left: 0%;
  }

  #WeConnectPeopleImg {
    left: -10%;
    /* right: 10%; */
    /* bottom: 300px; */
  }

  #FindTheRightGroup_h3 {
    position: relative;
    left: 0%;
    top: -52px;
    column-count: auto;
    word-wrap: break-word;
    width: 300px;
    /* display: none; */
  }

  #WeConnectPeopleImg {
    position: relative;
    top: 0px;
    /* bottom: 300px; */
  }

  /* #FindTheRightGroup_h3 {
    top: 0px;
  } */

  #idFindTheRightGroup_p {
    font-family: Barlow, sans-serif;
    margin: 35px 0 0;
    font-size: 21px;
    line-height: 1.43em;
    color: #040a17;
    column-count: auto;
    word-wrap: break-word;
    width: 300px;
    top: -27px;
    /* For making the rolling text on the far left */

    left: 0%;
  }

  #idFindTheRightGroup_img {
    left: 0%;
    top: 0px;
  }
}
