.DiscoverOurGroupsBlock_MainContainer {
  position: relative;
  bottom: 200px;
}

#DiscoverOurGroupsBlock_h3 {
  position: relative;
  left: 400px;
  /* font-size: 200px; */
  font-family: Barlow, sans-serif;
  color: #040a17;
  font-size: 44px;
  line-height: 1.13em;
  font-weight: 700;
  margin: 25px 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  /* For making the rolling text on the far left */
  left: 32%;
  right: 10%;
}

#DiscoverOurGroupsBlock_p {
  font-family: Barlow, sans-serif;
  margin: 35px 0 0;
  font-size: 21px;
  line-height: 1.43em;
  color: #040a17;
  column-count: auto;
  word-wrap: break-word;
  width: 600px;

  /* For making the rolling text on the far left */
  position: relative;
  left: 32%;
}

@media screen and (max-width: 600px) {
  .DiscoverOurGroupsBlock_MainContainer {
    position: relative;
    bottom: 0px;
  }

  #DiscoverOurGroupsBlock_h3 {
    position: relative;
    left: 20%;
    /* top: 100px; */
    column-count: auto;
    word-wrap: break-word;
    width: 300px;
  }
  #DiscoverOurGroupsBlock_p {
    position: relative;
    left: 20%;
    /* top: 100px; */
    column-count: auto;
    word-wrap: break-word;
    width: 300px;
  }

  /* .carousel__slide-item {
    left: 2.5%;
  } */
}
