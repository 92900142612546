@import url("https://fonts.googleapis.com/css?family=Roboto:700");

/* body {
  margin: 0px;
  font-family: "Roboto";
  text-align: center;
} */

#RollingTextContainer {
  color: #999;
  text-transform: uppercase;
  font-size: 36px;
  font-weight: bold;
  padding-top: 0px;
  padding-bottom: 70px;
  position: relative;
  text-align: center;
  width: 100%;
  bottom: 45%;
  display: block;
  /* For making the rolling text on the far left */
  left: 30%;
}

#RollingTextFlip {
  height: 50px;
  overflow: hidden;
}

#RollingTextFlip > div > div {
  color: #fff;
  padding: 4px 12px;
  height: 50px;
  margin-bottom: 40px;
  display: inline-block;
}

#RollingTextFlip div:first-child {
  animation: show 5s linear infinite;
}

#RollingTextFlip div div {
  background: #42c58a;
}
#RollingTextFlip div:first-child div {
  background: #4ec7f3;
}
#RollingTextFlip div:last-child div {
  background: #dc143c;
}

@keyframes show {
  0% {
    margin-top: -270px;
  }
  5% {
    margin-top: -180px;
  }
  33% {
    margin-top: -180px;
  }
  38% {
    margin-top: -90px;
  }
  66% {
    margin-top: -90px;
  }
  71% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -270px;
  }
}

/* p {
  position: fixed;
  width: 100%;
  bottom: 30px;
  font-size: 12px;
  color: #999;
  margin-top: 200px;
} */

@media screen and (max-width: 600px) {
  /* .typeWriterDiv {
    font-size: 1rem;
  } */

  #RollingTextContainer {
    /* For making the rolling text on the far left */
    left: -10%;
  }
}
