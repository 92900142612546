/* .signup__bg {
  background-image: url(https://images.unsplash.com/photo-1622556498246-755f44ca76f3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzJ8fG1lc3NhZ2UlMjBhcHB8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60);
  height: 92vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
} */

.signup-profile-pic__container {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: relative;
}

.signup-profile-pic {
  width: 100px;
  border-radius: 50%;
  border: 2px solid gray;
  object-fit: cover;
  height: 100px;
}

.add-picture-icon {
  position: absolute;
  bottom: 0;
  right: 10px;
  color: green;
  background: white;
  cursor: pointer;
  z-index: 99;
}

#SingupImage {
  position: relative;
  left: 150%;
  top: -600px;
}

@media screen and (max-width: 600px) {
  #SingupImage {
    display: none;
  }
}
