/* // $slide-count: 3;
// $slide-width: 30rem;

// html {
//   box-sizing: border-box;
//   font-size: 10px;
//   @media (max-width: 600px) {
//     font-size: 8px;
//     // $slide-count: 3;
//   }
// }

// body {
//   font-size: 1.5rem;
//   margin: 0;
//   font-family: Arial, Helvetica, sans-serif;
// }

// // *,
// *:before,
// *:after {
//   -webkit-box-sizing: border-box;
//   box-sizing: inherit;
// }

// ul {
//   list-style-type: none;
//   padding: 0;
// }

// a {
//   text-decoration: none;
//   color: inherit;
// }

// .carousel__wrap {
//   margin-top: 10%;
//   align-items: center;
//   display: flex;
//   flex: 1;
//   justify-content: center;
//   position: relative;
//   width: 80%;
//   left: 50%;
//   transform: translateX(-50%);
// }

// .carousel__inner {
//   height: 40rem;
//   position: relative;
//   width: calc(#{$slide-width * 3});
// }

// .carousel__container {
//   height: 100%;
//   overflow: hidden;
//   position: relative;
//   width: 100%;
// }

// .carousel__slide-list {
//   height: 100%;
//   left: 50%;
//   list-style-type: none;
//   margin: 0;
//   padding: 0;
//   position: absolute;
//   transform: translateX(-50%);
//   width: calc(#{($slide-count + 0.5) * $slide-width * 2});
// }

// .carousel__slide-item {
//   display: inline-block;
//   height: $slide-width;
//   margin: 0;
//   padding: 1rem;
//   position: absolute;
//   transition: all 0.3s;
//   width: $slide-width;
// }

// .carousel__slide-item-img-link {
//   cursor: zoom-in;
//   display: flex;
//   height: 100%;
//   overflow: hidden;
//   position: relative;
//   width: 100%;

//   img {
//     height: 100%;
//     object-fit: cover;
//     transition: all 0.5s ease;
//     width: 100%;
//   }

//   &::after {
//     align-items: center;
//     background: rgba(black, 0.5);
//     color: white;
//     // content: "read more";
//     display: flex;
//     height: 100%;
//     justify-content: center;
//     opacity: 0;
//     position: absolute;
//     transition: all 0.5s ease;
//     width: 100%;
//   }

//   &:hover {
//     &::after {
//       opacity: 1;
//     }

//     img {
//       transform: scale(1.3);
//     }
//   }
// }

// .carousel-slide-item__body {
//   bottom: -2.5rem;
//   height: 10%;
//   position: absolute;

//   h4 {
//     margin: 0.7rem 0 0;
//     text-transform: uppercase;
//   }

//   p {
//     font-size: 1.2rem;
//     line-height: 1.3;
//     margin: 0.7rem 0 0;
//   }
// }

// .carousel__btn {
//   align-items: center;
//   background: 0;
//   border: 0;
//   cursor: pointer;
//   display: flex;
//   justify-content: center;
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);

//   &--prev {
//     left: -10rem;
//   }

//   &--next {
//     right: -10rem;
//   }
// }

// .carousel__btn-arrow {
//   border: solid black;
//   border-width: 0 0.4rem 0.4rem 0;
//   height: 6rem;
//   padding: 3px;
//   width: 6rem;
//   z-index: 10;

//   &--left {
//     transform: rotate(135deg);
//   }

//   &--right {
//     transform: rotate(-45deg);
//   }
// }

// .carousel__dots {
//   display: inline-block;
//   left: 50%;
//   margin-top: 2rem;
//   position: absolute;
//   transform: translateX(-50%);

//   .dot {
//     background: #ccc;
//     border: 0;
//     border-radius: 50%;
//     cursor: pointer;
//     height: 2rem;
//     margin: 0 0.3rem;
//     outline: none;
//     transform: scale(0.5);
//     width: 2rem;

//     &.active {
//       background: black;
//     }
//   }
// } */

html {
  box-sizing: border-box;
  /* font-size: 10px; */
}
/* @media (max-width: 600px) {
  html {
    font-size: 8px;
  }
} */
body {
  font-size: 1.5rem;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: inherit;
  /* Newely added */
  font-size: 10px;
}
ul {
  list-style-type: none;
  padding: 0;
  /* Newely added */
  font-size: 10px;
}
a {
  text-decoration: none;
  color: inherit;
}
.carousel__wrap {
  margin-top: 10%;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  /* Newely added */
  font-size: 10px;
}
.carousel__inner {
  height: 40rem;
  position: relative;
  width: calc(90rem);
  /* Newely added */
  font-size: 10px;
}
.carousel__container {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  /* Newely added */
  font-size: 10px;
}
.carousel__slide-list {
  height: 100%;
  left: 50%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  width: calc(210rem);
  /* Newely added */
  font-size: 10px;
}
.carousel__slide-item {
  display: inline-block;
  height: 20rem;
  margin: 0;
  padding: 1rem;
  position: absolute;
  transition: all 0.3s;
  width: 20rem;
}
.carousel__slide-item-img-link {
  cursor: zoom-in;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.carousel__slide-item-img-link img {
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
  width: 100%;
}
.carousel__slide-item-img-link::after {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  width: 100%;
}
.carousel__slide-item-img-link:hover::after {
  opacity: 1;
}
.carousel__slide-item-img-link:hover img {
  transform: scale(1.3);
}
.carousel-slide-item__body {
  bottom: -2.5rem;
  height: 10%;
  position: absolute;
}
.carousel-slide-item__body h4 {
  margin: 0.7rem 0 0;
  text-transform: uppercase;
}
.carousel-slide-item__body p {
  font-size: 1.2rem;
  line-height: 1.3;
  margin: 0.7rem 0 0;
}
.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.carousel__btn--prev {
  left: -10rem;
}
.carousel__btn--next {
  right: -10rem;
}
.carousel__btn-arrow {
  border: solid black;
  border-width: 0 0.4rem 0.4rem 0;
  height: 2rem;
  padding: 3px;
  width: 2rem;
  z-index: 10;
}
.carousel__btn-arrow--left {
  transform: rotate(135deg);
  position: relative;
  left: 200%;
}
.carousel__btn-arrow--right {
  transform: rotate(-45deg);
  position: relative;
  left: -200%;
}
.carousel__dots {
  display: inline-block;
  left: 50%;
  margin-top: 2rem;
  position: absolute;
  transform: translateX(-50%);
}
.carousel__dots .dot {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 2rem;
  margin: 0 0.3rem;
  outline: none;
  transform: scale(0.5);
  width: 2rem;
}
.carousel__dots .dot.active {
  background: black;
}

@media screen and (max-width: 600px) {
  .carousel__slide-item {
    left: 2.5%;
  }

  .carousel__btn-arrow--left {
    display: none;
  }
  .carousel__btn-arrow--right {
    display: none;
  }
}
