.mainContainer {
  background-color: #f0f4f6;
}

.text {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
