.newsletterSubmitButton {
  /* background-color: #4caf50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    color: red; */
  position: relative;
  bottom: 70px;
  /* left: 200px; */
  left: 20%;
  right: 20%;
  height: 40px;
  width: 150px;
  display: inline-block;
  font-size: 16px;
  border-radius: 50px;
  background-color: #5edfff;
  border: none;
  color: #ffffff;
}

.newsletterInput {
  position: relative;
  left: -80%;
  right: 20%;
  border: none;
  float: left;
  width: 300%;
  height: 40px;
  border-radius: 30px;
}

@media screen and (max-width: 600px) {
  .newsletterSubmitButton {
    /* background-color: #4caf50;
          border: none;
          color: white;
          padding: 15px 32px;
          text-align: center;
          text-decoration: none;
          cursor: pointer;
          color: red; */
    position: relative;
    top: 0px;
    left: 20%;
    right: 20%;
    width: 150px;
    display: inline-block;
    font-size: 16px;
    border-radius: 50px;
    background-color: #5edfff;
    border: none;
    color: #ffffff;
    transform: translate(-50%, -50%);
  }

  .newsletterInput {
    border: none;
    float: left;
    left: -20%;
    right: 0%;
    width: 150%;
    height: 40px;
    border-radius: 30px;
  }
}
