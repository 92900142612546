.DiscoverNowbtn {
  /* position: relative;
  left: -25%;
  top: 100%;
  font-size: 20px;
  color: white;
  background: transparent;
  border-radius: 20px; */
  position: relative;
  bottom: 350px;
  /* left: 200px; */
  left: 20%;
  right: 20%;
  height: 40px;
  width: 150px;
  display: inline-block;
  font-size: 16px;
  border-radius: 50px;
  background-color: #5edfff;
  border: none;
  color: #ffffff;
}

@media screen and (max-width: 600px) {
  .DiscoverNowbtn {
    position: relative;
    font-size: 1rem;
    left: 20%;
    top: -400px;
  }
}
